import { gql } from 'graphql-request'

export const GetReceivalHistoryGql = gql`
  query listReceivalHistory(
    $stationRecordNumber: String!
    $fromDateReceived: Date!
    $toDateReceived: Date!
  ) {
    listReceivalHistory(
      request: {
        stationRecordNumber: $stationRecordNumber
        fromDateReceived: $fromDateReceived
        toDateReceived: $toDateReceived
      }
    ) {
      results {
        dateReceived
        purchaseAccountCode
        storageCentreCode
        totalBalesReceived
      }
      sumOfTotalBalesReceived
    }
  }
`
