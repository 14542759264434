import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { WoolContractAdditionalDetail } from '@/components/stations/wool-data/wool-contracts-table/contract-details-helpers'

type ContractDetailsSubtableProps = {
  data: WoolContractAdditionalDetail[]
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const ContractDetailsSubtable = ({
  data,
  noDataMessage = 'No values to display',
  isLoading,
  isError,
}: ContractDetailsSubtableProps) => {
  const columns = useMemo<MRT_ColumnDef<WoolContractAdditionalDetail>[]>(
    () => [
      {
        accessorKey: 'key',
        header: '',
      },
      {
        accessorKey: 'target',
        header: 'Target',
      },
      {
        accessorKey: 'range',
        header: 'Range',
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Contract details',
    },
    initialState: {
      ...hubTableDefaultProps.initialState,
    },
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
  })

  return <MantineReactTable table={table} />
}
