import { useMemo } from 'react'
import { NumberFormatter } from '@mantine/core'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import {
  OnChangeFn,
  PaginationState,
  SortingState,
  ColumnFiltersState,
} from '@tanstack/react-table'
import {
  DEFAULT_PAGINATION_SIZE,
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { ListBaleDetailsOrderByOptions, ListBaleDetailsResponse } from '@/loom-gql/graphql'

interface BaleDetailsTableProps {
  data?: ListBaleDetailsResponse | null
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
  sorting?: SortingState
  setSorting?: OnChangeFn<SortingState>
  pagination?: PaginationState
  setPagination?: OnChangeFn<PaginationState>
  columnFilters?: ColumnFiltersState
  setColumnFilters?: OnChangeFn<ColumnFiltersState>
}

export const BaleDetailsTable = ({
  data = null,
  noDataMessage = 'No bale details available for this station',
  isLoading,
  isError,
  sorting = [],
  setSorting,
  pagination = { pageIndex: 0, pageSize: DEFAULT_PAGINATION_SIZE },
  setPagination,
  columnFilters,
  setColumnFilters,
}: BaleDetailsTableProps) => {
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: ListBaleDetailsOrderByOptions.BaleNumber,
        accessorKey: 'baleNumber',
        header: 'Bale No',
        enableSorting: true,
        sortDescFirst: false,
        Footer: () => <>Totals</>,
        filterVariant: 'text',
      },
      {
        accessorKey: 'description',
        header: 'Description',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: ListBaleDetailsOrderByOptions.LineNumber,
        accessorKey: 'lineNumber',
        header: 'Line No',
        enableSorting: true,
        sortDescFirst: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'netKGs',
        header: 'Net KGs',
        Cell: ({ cell }) => (
          <NumberFormatter value={cell.getValue<number>()} thousandSeparator decimalScale={1} />
        ),
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
        },
        enableColumnFilter: false,
        enableSorting: false,
        Footer: () => (
          <NumberFormatter value={data?.totalNetKGs} thousandSeparator decimalScale={1} />
        ),
      },
      {
        accessorKey: 'referenceNumber',
        header: 'Reference No',
        enableSorting: false,
        filterVariant: 'text',
      },
      {
        accessorKey: 'sale',
        header: 'Sale No',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'lotNumber',
        header: 'Lot No',
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'saleOutcome',
        header: 'Sale Outcome',
        enableSorting: false,
        enableColumnFilter: false,
      },
    ],
    [data?.totalNetKGs]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data: data?.results ?? [],
    state: {
      isLoading,
      sorting,
      columnFilters,
      pagination,
      showAlertBanner: isError,
    },
    initialState: {
      ...hubTableDefaultProps.initialState,
      showColumnFilters: true,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      'aria-label': 'Bale Details',
    },
    // Sorting
    enableSorting: true,
    manualSorting: true,
    onSortingChange: setSorting,
    enableMultiSort: false,
    // Pagination
    manualPagination: true,
    rowCount: data?.totalResultCount,
    pageCount: data?.totalPageCount,
    onPaginationChange: setPagination,
    // Column filters
    enableFilters: true,
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    enableFilterMatchHighlighting: false,
  })

  return <MantineReactTable table={table} />
}
