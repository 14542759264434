import { gql } from 'graphql-request'

export const GetCreditorHistoryGql = gql`
  query listCreditorHistory(
    $stationRecordNumber: String!
    $includeStationGroup: Boolean!
    $toPaymentDate: Date!
    $fromPaymentDate: Date!
  ) {
    listCreditorHistory(
      request: {
        stationRecordNumber: $stationRecordNumber
        includeStationGroup: $includeStationGroup
        fromPaymentDate: $fromPaymentDate
        toPaymentDate: $toPaymentDate
      }
    ) {
      results {
        stationRecordNumber
        stationBrand
        saleAndSeason
        paymentDate
        accountSalesDate
        grossProceedsExGST
        netProceedsGSTInclusive
        totalBalesInPayment
        totalNetKgInPayment
      }
      sumOfGrossProceedsExGST
      sumOfNetProceedsGSTInclusive
      sumOfTotalBales
      sumOfTotalNetKg
    }
  }
`
