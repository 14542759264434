import { gql } from 'graphql-request'

export const GetTypingProgramData = gql`
  query typingProgramData($referenceNumber: String!) {
    typingProgramData(request: { woolLotReferenceNumber: $referenceNumber }) {
      baleHeaderPurchaseBrand
      baleHeaderPurchaseDescription
      coreTestId
      coreTestBoxLocation
      coreTestBurrPercentage
      coreTestCoefficientOfVariationLaserscan
      coreTestColourYZ
      coreTestCurvatureMeanLaser
      coreTestHauteurLength
      coreTestMicronAirflow
      coreTestMicronLaserscan
      coreTestMicronOfda
      coreTestPointOfBreakBase
      coreTestPointOfBreakMid
      coreTestPointOfBreakTip
      coreTestSeedShivePercentage
      coreTestStapleAverageStrength
      coreTestStapleCoefficientOfLengthVariation
      coreTestStapleLengthGreasy
      coreTestStapleStrength
      coreTestVegetableMatterBase
      coreTestYield1
      purchaseAccountName
      purchaseAccountWarning
      stationRecordNumber
      woolLotId
      woolLotBales
      woolLotCode
      woolLotReferenceNumber
      woolLotSaleNumber
      woolLotSeason
      woolLotsTotalKilosClean
      woolLotsTotalKilosNet
      woolLotLineNumber
      qualityScheme
      qualitySchemeWarning
      woolLotActions
      woolLotTypeCurrent
      woolLotInternalComment
    }
  }
`
