import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Group, Stack, Title } from '@mantine/core'
import { ReceivalHistoryTable } from '@/components/stations/wool-data/receival-history-table/receival-history-table'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { GetReceivalHistoryGql } from '@/graphql/queries/get-receival-history.graphql'
import { SeasonDropdown } from '@/components/shared/season-dropdown/season-dropdown'

export function ReceivalHistory() {
  // Get the station record number from the URL
  const { stationRecordNumber } = useParams()

  // State for the season start and end dates
  const [seasonStartDate, setSeasonStartDate] = useState<Date | undefined>(undefined)
  const [seasonEndDate, setSeasonEndDate] = useState<Date | undefined>(undefined)

  // Get the receival history data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['receivalHistoryData', stationRecordNumber, seasonStartDate, seasonEndDate],
    gql: GetReceivalHistoryGql,
    queryParams: {
      stationRecordNumber,
      fromDateReceived: seasonStartDate,
      toDateReceived: seasonEndDate,
    },
    enabled: !!stationRecordNumber && !!seasonStartDate && !!seasonEndDate,
  })

  return (
    <Stack>
      <Group justify="space-between">
        <Title order={2} data-testid="station-wool-data-receival-history">
          Receival History
        </Title>
        <SeasonDropdown
          seasonsBeforeCurrent={5}
          setSeasonStartDate={setSeasonStartDate}
          setSeasonEndDate={setSeasonEndDate}
        />
      </Group>
      <ReceivalHistoryTable
        data={data?.listReceivalHistory?.results ?? []}
        sumOfTotalBalesReceived={data?.listReceivalHistory?.sumOfTotalBalesReceived ?? null}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  )
}
