import { MRT_DensityState } from 'mantine-react-table'
import classes from '@/components/shared/table/table.module.css'

export const DEFAULT_PAGINATION_SIZE = 15

/**
 * Default properties for uses of Mantine React Table
 * within the Hub.
 */
export const hubTableDefaultProps = {
  enableSorting: false,
  enableRowSelection: false,
  enableColumnOrdering: false,
  enableGlobalFilter: false,
  enableHiding: false,
  enableFilters: false,
  enableColumnActions: false,
  initialState: {
    density: 'xs' as MRT_DensityState,
    pagination: { pageIndex: 0, pageSize: DEFAULT_PAGINATION_SIZE },
  },
  mantineTableProps: {
    withColumnBorders: true,
    withRowBorders: true,
    withTableBorder: false,
    highlightOnHover: true,
    className: classes.table,
  },
  mantineTableBodyCellProps: {
    className: classes.bodyCell,
  },
  mantineTableFooterCellProps: {
    className: classes.footerCell,
  },
  mantineTableFooterRowProps: {
    className: classes.footer,
  },
  mantineTopToolbarProps: {
    className: classes.topToolbar,
  },
  defaultColumn: {
    size: 50,
    minSize: 20,
    maxSize: Number.MAX_SAFE_INTEGER,
  },
  mantinePaperProps: {
    shadow: 'none',
  },
}

export const hubTableDefaultAlertBannerProps = (isError: boolean | undefined) =>
  isError
    ? {
        color: 'red',
        children: 'Error loading data for table',
      }
    : undefined
