import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Group, Stack, Switch, Title } from '@mantine/core'
import { ColumnFiltersState } from '@tanstack/table-core'
import { IconDownload } from '@tabler/icons-react'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { GetLotDetailsGql } from '@/graphql/queries/get-lot-details.graphql'
import {
  LotDetailsTable,
  ResultOptions,
} from '@/components/stations/wool-data/lot-details-table/lot-details-table'
import { Breed, StockAge, WoolSoldStatus } from '@/loom-gql/graphql'
import { generateCSV } from '@/helpers/generate-csv/generate-csv'
import { SeasonDropdown } from '@/components/shared/season-dropdown/season-dropdown'
import { BreedDropdown } from '@/components/shared/breed-dropdown/breed-dropdown'
import { StockAgeDropdown } from '@/components/shared/stock-age-dropdown/stock-age-dropdown'

const ResultOptionToWoolSoldStatus = {
  [ResultOptions.Sold]: WoolSoldStatus.SoldOrBin,
  [ResultOptions.Unsold]: WoolSoldStatus.Unsold,
}

export function LotDetails() {
  const [seasonStartDate, setSeasonStartDate] = useState<Date | undefined>(undefined)
  const [viewAllUnsoldLots, setViewAllUnsoldLots] = useState(false)
  const [breed, setBreed] = useState<Breed | null>()
  const [stockAge, setStockAge] = useState<StockAge | null>()

  // Get the station record number from the URL
  const { stationRecordNumber } = useParams()

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  // Determine what sold result status to filter by
  const soldResultToFilterBy: WoolSoldStatus | null = useMemo(() => {
    // Use the 'view all unsold lots' switch to determine if we should show all unsold lots
    if (viewAllUnsoldLots) {
      return WoolSoldStatus.Unsold
    }

    // Use the column filters to determine
    return ResultOptionToWoolSoldStatus[
      columnFilters.find((dict) => dict.id === 'result')?.value as ResultOptions
    ]
  }, [viewAllUnsoldLots, columnFilters])

  const enableSeasonFilter: boolean = useMemo(() => !viewAllUnsoldLots, [viewAllUnsoldLots])
  const seasonFilterValue: string | undefined = useMemo(() => {
    if (viewAllUnsoldLots) {
      return undefined
    }

    return seasonStartDate?.getFullYear().toString().substring(2)
  }, [viewAllUnsoldLots, seasonStartDate])

  // Get the lot details data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: [
      'lotDetailsData',
      stationRecordNumber,
      columnFilters,
      seasonStartDate,
      breed,
      stockAge,
      seasonFilterValue,
      soldResultToFilterBy,
    ],
    gql: GetLotDetailsGql,
    queryParams: {
      stationRecordNumber,
      saleAndSeason: columnFilters.find((dict) => dict.id === 'saleAndSeason')?.value as string,
      season: seasonFilterValue,
      purchaseAccountName: columnFilters.find((dict) => dict.id === 'purchaseAccountName')
        ?.value as string,
      result: soldResultToFilterBy,
      breed,
      stockAge,
    },
    enabled: !!stationRecordNumber,
  })

  const getCsvFilename = () => `${stationRecordNumber}-lot-data.csv`

  return (
    <Stack>
      <Group>
        <Title order={2} data-testid="station-wool-data-lot-details" mr="auto">
          Lot Data
        </Title>
        <Switch
          label="View all unsold lots"
          checked={viewAllUnsoldLots}
          onChange={(event) => setViewAllUnsoldLots(event.currentTarget.checked)}
        />
        <BreedDropdown setBreed={setBreed} />
        <StockAgeDropdown setStockAge={setStockAge} />
        <SeasonDropdown
          seasonsBeforeCurrent={5}
          seasonsAfterCurrent={1}
          setSeasonStartDate={setSeasonStartDate}
          setSeasonEndDate={() => undefined} // Season end date is currently not used by this page
          enabled={enableSeasonFilter}
        />
        <Button
          onClick={() => generateCSV(data?.listLotDetails?.results, getCsvFilename())}
          leftSection={<IconDownload />}
        >
          Download
        </Button>
      </Group>
      <LotDetailsTable
        data={data?.listLotDetails?.results ?? []}
        isLoading={isLoading}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        enableResultFilter={!viewAllUnsoldLots}
      />
    </Stack>
  )
}
