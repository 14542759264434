import { useEffect, useState } from 'react'
import { Select } from '@mantine/core'
import { Breed } from '@/loom-gql/graphql'
import { BREED_LABELS } from '@/constants/breeds'

type BreedDropdownProps = {
  setBreed: (value: Breed | null) => void
  initialBreed?: Breed
}

/**
 * A dropdown component for selecting a breed.
 *
 * @param {Object} props - The properties object.
 * @param {function} props.setBreed - Function to set the selected breed.
 * @param {string | null} props.initialBreed - The initial breed to be selected.
 *
 * @returns {JSX.Element} The BreedDropdown component.
 */
export function BreedDropdown({ setBreed, initialBreed }: BreedDropdownProps) {
  const [breedOption, setBreedOption] = useState<string | null>(initialBreed || Breed.All)

  // Update the breed when the dropdown changes
  useEffect(() => {
    setBreed(breedOption as Breed | null)
  }, [breedOption])

  return (
    <Select
      data={generateBreedOptions()}
      value={breedOption}
      onChange={setBreedOption}
      allowDeselect={false}
      name="breed-select"
      aria-label="Breed select"
    />
  )
}

/**
 * Generates an array of breed options from the BREED_LABELS constant.
 * Each option is an object containing a `value` and a `label` property.
 *
 * @returns {Array<{ value: string, label: string }>} An array of breed options.
 */
export function generateBreedOptions() {
  return Object.entries(BREED_LABELS).map(([value, label]) => ({ value, label }))
}
