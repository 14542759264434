import { Button, Group, Stack, Title, Text } from '@mantine/core'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { PAGE_TITLES } from '@/constants/page-titles'
import { Search } from '@/components/shared/search/search'
import { StationsSearchTable } from '@/components/stations/search/station-search-table'
import { useStationSearch } from '@/components/stations/search/use-station-search'
import { SEARCH_LIMIT, SEARCH_TERM_PARAM_NAME } from '@/pages/stations/constants'

export function StationSearchPage() {
  const {
    searchTermEntry,
    setSearchTermEntry,
    handleSearchRequest,
    stationSearchData,
    isLoading,
    isFetching,
    isError,
  } = useStationSearch(SEARCH_LIMIT)

  const showTable = stationSearchData || isLoading || isFetching

  return (
    <PageContainer title={PAGE_TITLES.STATION_SEARCH}>
      <Stack>
        <Title>Stations</Title>
        <Group>
          <Search
            autoFocus
            placeholder="Search by Station ID, Brand, or Trading Name"
            size="md"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearchRequest()
              }
            }}
            onChange={(e) => setSearchTermEntry(e.currentTarget.value)}
            value={searchTermEntry}
          />
          <Button size="md" type="submit" onClick={() => handleSearchRequest()}>
            Search
          </Button>
        </Group>
        {showTable && (
          <>
            <StationsSearchTable
              data={stationSearchData?.stationSearch}
              isError={isError}
              isFetching={isFetching}
              isLoading={isLoading}
            />
            {stationSearchData?.stationSearch.length === SEARCH_LIMIT && (
              <Text size="sm">Limited to {SEARCH_LIMIT} results.</Text>
            )}
          </>
        )}
      </Stack>
    </PageContainer>
  )
}
export { SEARCH_TERM_PARAM_NAME }
