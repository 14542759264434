import { WoolContract } from '@/loom-gql/graphql'

export type WoolContractAdditionalDetail = {
  key: string
  target?: string
  range?: string
}

export const transposeDataToContractDetails = (
  data: WoolContract
): WoolContractAdditionalDetail[] => [
  {
    key: 'Micron',
    target: data.targetMicron?.toString() || '',
    range: `${data.micronMin?.toString() || ''} - ${data.micronMax?.toString() || ''}`,
  },
  {
    key: 'VM',
    target: data.targetVegetableMatter?.toString() || '',
  },
  {
    key: 'Yield',
    target: data.targetMinimumYield?.toString() || '',
  },
  {
    key: 'Length',
    target: data.targetGreasyLength?.toString() || '',
    range: `${data.greasyLengthMin?.toString() || ''} - ${data.greasyLengthMax?.toString() || ''}`,
  },
  {
    key: 'Strength',
    target: data.targetSampleStrength?.toString() || '',
  },
  {
    key: 'Style',
    target: data.styleDescription?.toString() || '',
  },
  {
    key: 'Colour',
    target: data.colourDescription?.toString() || '',
  },
]
