/**
 * Given a year string, returns the start and end dates of the wool season.
 *
 * The start of the season is the 1st of July of that year, and the end of the season is the 30th of June the following year.
 *
 * @param {string} year - The year string.
 * @returns {{ startDate: Date, endDate: Date }} An object containing the start and end dates of the wool season.
 */
export function getWoolSeasonDates(year: string) {
  const startYear = parseInt(year, 10)
  const startDate = new Date(startYear, 6, 1)
  const endDate = new Date(startYear + 1, 5, 30, 23, 59, 59)
  return { startDate, endDate }
}
