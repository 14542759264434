import { gql } from 'graphql-request'

export const GetBaleDetailsGql = gql`
  query listBaleDetails(
    $stationRecordNumber: String!
    $orderByAscending: Boolean!
    $orderBy: ListBaleDetailsOrderByOptions!
    $currentPage: Int!
    $pageSize: Int!
    $referenceNumber: String
    $baleNumber: String
    $season: String
  ) {
    listBaleDetails(
      request: {
        stationRecordNumber: $stationRecordNumber
        orderByAscending: $orderByAscending
        orderBy: $orderBy
        currentPage: $currentPage
        pageSize: $pageSize
        referenceNumber: $referenceNumber
        baleNumber: $baleNumber
        season: $season
      }
    ) {
      currentPage
      totalPageCount
      totalResultCount
      totalNetKGs
      results {
        referenceNumber
        baleNumber
        lineNumber
        lotNumber
        description
        netKGs
        sale
        saleOutcome
      }
    }
  }
`
