import { stringify } from 'csv-stringify/browser/esm/sync'
import { saveAs } from 'file-saver'

export const generateCSV = (results?: Array<any>, filename?: string) => {
  if (!results || results.length === 0) {
    return
  }
  const data = stringify(results, { header: true })
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
  saveAs(blob, filename || 'export.csv')
}
