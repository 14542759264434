import { gql } from 'graphql-request'

export const GetWoolContractsGql = gql`
  query listWoolContracts($stationRecordNumber: String!) {
    listWoolContracts(request: { stationRecordNumber: $stationRecordNumber }) {
      sumOfBalesContracted
      results {
        averagePriceCLN
        balesContracted
        colourDescription
        contractDeliveryDate
        contractName
        greasyLengthMax
        greasyLengthMin
        micronMax
        micronMin
        purchaseAccountRecordNumber
        styleDescription
        targetGreasyLength
        targetMicron
        targetMinimumYield
        targetSampleStrength
        targetVegetableMatter
      }
    }
  }
`
