/**
 * Given a date, return the season year.
 * Season year starts from July 1st and ends on June 30th.
 *
 * For example, given a date of 2021-07-01, return '2021'.
 * Given a date of 2021-06-30, return '2020'.
 * Given a date of 2021-01-01, return '2020'.
 *
 * @param {Date} date - The date to get the season year for. Leave out to use the current date.
 * @returns {number} The season year.
 */
export function getSeasonYear(date: Date = new Date()): number {
  const year = date.getFullYear()
  return date.getMonth() >= 6 ? year : year - 1
}
