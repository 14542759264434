import { StockAge } from '@/loom-gql/graphql'

export const STOCK_AGE_LABELS = {
  [StockAge.All]: 'All stock',
  [StockAge.Ewes]: 'Ewes',
  [StockAge.Hoggets]: 'Hoggets',
  [StockAge.Lambs]: 'Lambs',
  [StockAge.Wethers]: 'Wethers',
  [StockAge.Unclassified]: 'Unclassified',
}

/**
 * Converts a stock age value to its corresponding string label.
 *
 * @param stockAge - The stock age value to convert.
 * @returns The string label corresponding to the given stock age value.
 */
export const stockAgeValueToString = (stockAge: StockAge) => STOCK_AGE_LABELS[stockAge]
