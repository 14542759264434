import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { Autocomplete, Flex, Loader, Skeleton, Stack, TextInput, Textarea } from '@mantine/core'
import { useDebouncedCallback } from '@mantine/hooks'
import { IconAlertTriangle } from '@tabler/icons-react'
import { EnterOrScanAlert } from '@/components/shared/enter-or-scan-alert'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import woolTypeOptions from '@/components/typing/program/wool-types.json'
import classes from './typing-program-form.module.css'

export const debounceDelay: number = 500

export type InitialTypingValues = {
  type: string
  comment: string
  actions: string
}

type UpdateState = {
  type: boolean
  comment: boolean
  actions: boolean
}

export interface TypingProgramFormProps {
  referenceNumberFromParams: string | null
  noResults: boolean
  loadingState: boolean
  updateActions: (actions: string) => void
  updateComment: (comment: string) => void
  updateType: (type: string) => void
  updating?: UpdateState | null
  initialTypingValues?: InitialTypingValues | null
}

export function TypingProgramForm({
  referenceNumberFromParams,
  noResults,
  loadingState,
  updateActions,
  updateComment,
  updateType,
  updating,
  initialTypingValues,
}: TypingProgramFormProps) {
  const [hasWoolTypeError, setHasWoolTypeError] = useState<boolean>(false)

  // Manage controlled state for the inputs
  const [woolType, setWoolType] = useState<string | undefined>('')
  const [internalComment, setInternalComment] = useState<string | undefined>('')
  const [actions, setActions] = useState<string | undefined>('')

  const woolData = useMemo(() => [...woolTypeOptions.fineWool, ...woolTypeOptions.strongWool], [])

  const actionsRef = useRef<HTMLTextAreaElement>(null)
  const commentRef = useRef<HTMLInputElement>(null)
  const typeRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const handleActionsChange = useDebouncedCallback(async (value: string) => {
    updateActions(value)
  }, debounceDelay)

  const handleCommentChange = useDebouncedCallback(async (value: string) => {
    updateComment(value)
  }, debounceDelay)

  const handleTypeChange = useDebouncedCallback(async (value: string) => {
    updateType(value)
  }, debounceDelay)

  // This useEffect block is responsible for updating the form state from the parent component
  useEffect(() => {
    if (loadingState || !initialTypingValues) {
      setWoolType('')
      setInternalComment('')
      setActions('')
      return
    }

    if (initialTypingValues?.type !== woolType) {
      setWoolType(initialTypingValues?.type ?? '')
    }
    if (initialTypingValues?.comment !== internalComment) {
      setInternalComment(initialTypingValues?.comment ?? '')
    }
    if (initialTypingValues?.actions !== actions) {
      setActions(initialTypingValues?.actions ?? '')
    }
  }, [initialTypingValues, loadingState])

  // Set error state of wool type input
  useEffect(() => {
    if (!woolType) {
      setHasWoolTypeError(false)
      return
    }

    const woolDataType = woolData.find((wool) => wool === woolType)
    setHasWoolTypeError(!woolDataType)
  }, [woolType])

  const loader = useMemo(() => <Loader size={20} />, [])
  const warning = useMemo(
    () => (
      <IconAlertTriangle
        className={classes.warningIcon}
        size={20}
        aria-label="Unknown Type"
        role="img"
      />
    ),
    []
  )

  const focusAndCursorToEnd = (input: HTMLInputElement | HTMLTextAreaElement | null) => {
    if (!input) return

    input.focus()
    input.selectionStart = input.value.length
    input.selectionEnd = input.value.length
  }

  // Handle the enter key press to move focus to the next input
  // Ignores the enter key press if the user is focused on the actions textarea
  const enterTabber = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return

    const isFocusedTypingInput = [typeRef.current, commentRef.current].includes(
      document.activeElement as HTMLInputElement
    )
    if (isFocusedTypingInput) {
      if (typeRef.current === document.activeElement) {
        setTimeout(() => focusAndCursorToEnd(commentRef.current))
        return
      }
      if (commentRef.current === document.activeElement) {
        e.preventDefault()
        focusAndCursorToEnd(actionsRef.current)
      }
    }
  }

  useEffect(() => {
    if (!loadingState && typeRef.current && typeRef.current !== document.activeElement) {
      focusAndCursorToEnd(typeRef.current)
    }

    if (formRef.current) {
      formRef.current?.removeEventListener('keydown', enterTabber)
      formRef.current?.addEventListener('keydown', enterTabber)
    }

    return () => {
      if (formRef.current) {
        formRef.current?.removeEventListener('keydown', enterTabber)
      }
    }
  }, [formRef, loadingState, typeRef])

  const getContent = () => {
    // If no results found, show the alert and the welcome message
    if (noResults) {
      return (
        <Flex gap="md" direction="column">
          <EnterOrScanAlert
            title="Enter or scan a reference number"
            message="Scan the sample barcode or select the Enter Reference button."
          />
          {referenceNumberFromParams && (
            <IconAlert level={AlertLevel.WARNING} title="No results found" />
          )}
        </Flex>
      )
    }

    // If not yet scanned, show the welcome message
    if (!referenceNumberFromParams) {
      return (
        <EnterOrScanAlert
          title="Enter or scan a reference number"
          message="Scan the sample barcode or select the Enter Reference button."
        />
      )
    }

    // Custom styling classes
    const autoCompleteInputClasses = clsx({
      [`${classes.autocompleteInputError}`]: hasWoolTypeError,
    })
    const autoCompleteDropdownClasses = clsx(classes.autocompleteDropdown)

    return (
      <form ref={formRef}>
        <Stack>
          <Skeleton visible={loadingState}>
            <Autocomplete
              ref={typeRef}
              name="type"
              aria-label="Type input"
              placeholder="Type"
              disabled={loadingState}
              value={woolType}
              classNames={{
                input: autoCompleteInputClasses,
                dropdown: autoCompleteDropdownClasses,
              }}
              rightSection={
                <>
                  {updating?.type && loader}
                  {hasWoolTypeError && !updating?.type && warning}
                </>
              }
              data={woolTypeOptions.common}
              onChange={(value) => {
                setWoolType(value)
                handleTypeChange(value)
              }}
            />
          </Skeleton>
          <Skeleton visible={loadingState}>
            <TextInput
              ref={commentRef}
              name="comment"
              aria-label="Comment input"
              placeholder="Comment"
              disabled={loadingState}
              value={internalComment}
              onChange={(event) => {
                setInternalComment(event.currentTarget.value)
                handleCommentChange(event.currentTarget.value)
              }}
              rightSection={updating?.comment && loader}
            />
          </Skeleton>
          <Skeleton visible={loadingState}>
            <Textarea
              ref={actionsRef}
              classNames={{
                section: classes.textareaSection,
              }}
              name="actions"
              aria-label="Actions input"
              placeholder="Internal message requests"
              disabled={loadingState}
              rows={4}
              value={actions}
              onChange={(event) => {
                setActions(event.currentTarget.value)
                handleActionsChange(event.currentTarget.value)
              }}
              rightSection={updating?.actions && loader}
            />
          </Skeleton>
        </Stack>
      </form>
    )
  }

  return <>{getContent()}</>
}
