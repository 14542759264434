import { useMemo } from 'react'
import { Grid, NumberFormatter } from '@mantine/core'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import {
  hubTableDefaultAlertBannerProps,
  hubTableDefaultProps,
} from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { formatDate } from '@/helpers/format-date/format-date'
import { WoolContract } from '@/loom-gql/graphql'
import { ContractDetailsSubtable } from '@/components/stations/wool-data/wool-contracts-table/contract-details-subtable'
import { transposeDataToContractDetails } from '@/components/stations/wool-data/wool-contracts-table/contract-details-helpers'

interface WoolContractsTableProps {
  data: WoolContract[]
  sumOfBalesContracted?: number
  noDataMessage?: string
  isLoading?: boolean
  isError?: boolean
}

export const WoolContractsTable = ({
  data = [],
  sumOfBalesContracted = 0,
  noDataMessage = 'No records to display',
  isLoading,
  isError,
}: WoolContractsTableProps) => {
  const columns = useMemo<MRT_ColumnDef<WoolContract>[]>(
    () => [
      {
        accessorKey: 'contractName',
        header: 'Contract Name',
        Footer: () => <>Totals</>,
      },
      {
        accessorKey: 'purchaseAccountRecordNumber',
        header: 'Contract Reference',
      },
      {
        accessorKey: 'balesContracted',
        header: 'Bales Contracted',
        Footer: () => <NumberFormatter value={sumOfBalesContracted} thousandSeparator />,
      },
      {
        accessorKey: 'targetMicron',
        header: 'Target Micron',
        Cell: ({ cell }) => <NumberFormatter value={cell.getValue<number>()} decimalScale={2} />,
      },
      {
        accessorKey: 'contractDeliveryDate',
        header: 'Delivery Date',
        Cell: ({ cell }) => formatDate(cell.getValue<Date>(), 'DD/MM/YYYY'),
      },
      {
        accessorKey: 'averagePriceCLN',
        header: 'Average Price (Clean C/KG)',
        Cell: ({ cell }) => (
          <NumberFormatter
            prefix="$"
            value={cell.getValue<number>()}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        ),
      },
    ],
    [sumOfBalesContracted]
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    state: {
      isLoading,
      showAlertBanner: isError,
    },
    mantineToolbarAlertBannerProps: hubTableDefaultAlertBannerProps(isError),
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
    mantineTableProps: {
      ...hubTableDefaultProps.mantineTableProps,
      withColumnBorders: false,
      'aria-label': 'Wool Contracts',
    },
    renderDetailPanel: ({ row }) => {
      const additionalData = transposeDataToContractDetails(row.original)
      return (
        <Grid>
          <Grid.Col span={{ base: 12, lg: 4 }}>
            <ContractDetailsSubtable data={additionalData} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 8 }}>{/* Lot details subtable goes here */}</Grid.Col>
        </Grid>
      )
    },
  })

  return <MantineReactTable table={table} />
}
