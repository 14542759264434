import { useEffect } from 'react'
import { Flex, Button, Modal, Text, TextInput, Group, Stack, VisuallyHidden } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useDisclosure, useHotkeys } from '@mantine/hooks'
import { IconPackage, IconScan } from '@tabler/icons-react'
import clsx from 'clsx'
import { useScanner } from '@/hooks/use-scanner'
import classes from './wool-lot-lookup.module.css'

type WoolLotLookupProps = {
  setReferenceNumber: (value: string | null) => void
  setBoxLocation: (value: string | null) => void
  lookupResponse?: QueryStatus
  boxLocationValue?: string | undefined | null
  isVerticalLayout?: boolean
}

type QueryStatus = {
  coreTestId?: string
  woolLotRecordNumber?: string
  isLoading: boolean
  isSuccess: boolean
}

/**
 * WoolLotLookup component provides a user interface for looking up wool lot reference numbers
 * and box locations. It supports scanning functionality and modal dialogs for entering data.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setReferenceNumber - Function to set the reference number.
 * @param {Function} props.setBoxLocation - Function to set the box location.
 * @param {Object} props.lookupResponse - Response object for the lookup operation.
 * @param {string | undefined | null} props.boxLocationValue - Current box location value to display in the box location modal.
 * @param {StyleProp<React.CSSProperties['flexDirection']>} props.flexDirection - Flex direction for buttons group (row | row-reverse | column | column-reverse). Defaults to 'row'.
 *
 * @returns {JSX.Element} The WoolLotLookup component.
 */
export function WoolLotLookup({
  setReferenceNumber,
  setBoxLocation,
  lookupResponse,
  boxLocationValue,
  isVerticalLayout,
}: WoolLotLookupProps) {
  useHotkeys([
    ['x', () => handleRefModalOpen()],
    ['b', () => handleBoxModalOpen()],
  ])

  const { detachScan, attachScan } = useScanner({ hasScanned: setReferenceNumber })

  const [isRefModalOpen, { open: openRefModal, close: closeRefModal }] = useDisclosure(false)
  const [isBoxModalOpen, { open: openBoxModal, close: closeBoxModal }] = useDisclosure(false)

  const referenceForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      referenceNumber: '',
    },
  })
  const boxLocationForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      boxLocation: boxLocationValue,
    },
  })
  boxLocationForm.setFieldValue('boxLocation', boxLocationValue)

  const handleRefModalClose = () => {
    attachScan()
    closeRefModal()
  }
  const handleRefModalOpen = () => {
    referenceForm.setFieldValue('referenceNumber', '')
    detachScan()
    openRefModal()
  }

  const handleBoxModalClose = () => {
    attachScan()
    closeBoxModal()
  }

  const handleBoxModalOpen = () => {
    if (!lookupResponse?.coreTestId) {
      return
    }
    boxLocationForm.setFieldValue('boxLocation', '')
    detachScan()
    openBoxModal()
  }

  // The reference number entered via the from is propagated to the parent component via setReferenceNumber
  const handleReferenceFormSubmit = (values: { referenceNumber: string | null }) => {
    if (!isRefModalOpen) return

    const updatedVal = values.referenceNumber?.trim() || null
    if (updatedVal) {
      setReferenceNumber(updatedVal)
    }
    handleRefModalClose()
  }

  const handleBoxLocationFormSubmit = (values: { boxLocation: string | undefined | null }) => {
    if (!isBoxModalOpen) return

    const updatedVal = values.boxLocation?.trim() || null
    setBoxLocation(updatedVal ?? '')
    handleBoxModalClose()
  }

  const handleFocus = (event: { target: { select: () => void } }) => {
    event.target.select()
  }

  // Close the lookup modal when the response is successful
  useEffect(() => {
    if (lookupResponse?.isSuccess && isRefModalOpen) {
      handleRefModalClose()
    }
  }, [lookupResponse?.isSuccess])

  // Clear reference number in the parent component when browser back button is used
  useEffect(() => {
    window.onpopstate = () => {
      setReferenceNumber(null)
    }
  })
  // If we explicitly declare no dependencies by passing in an empty array [], useEffect will only run once, thus making this pattern legitimate for event handler attachment

  const buttonContainerClasses = clsx(classes.container, {
    [classes.containerVerticalLayout]: isVerticalLayout,
  })

  return (
    <Stack gap="xl">
      {/* TODO: Flex children need 'flex-grow: 1' when viewport is 'md' or larger. The 'Group' element 'grow' prop doesn't support sizing. */}
      <Flex gap="md" className={buttonContainerClasses}>
        <Button
          loading={lookupResponse?.isLoading}
          loaderProps={{ type: 'dots' }}
          onClick={handleRefModalOpen}
        >
          Enter Reference (X)
        </Button>
        {!!lookupResponse?.coreTestId && (
          <Button
            onClick={handleBoxModalOpen}
            variant="outline"
            leftSection={<IconPackage color="var(--mantine-color-blue-6)" />}
          >
            <VisuallyHidden>Box location: </VisuallyHidden>
            {boxLocationValue ? `${boxLocationValue} (B)` : 'Box (B)'}
          </Button>
        )}
      </Flex>

      <Modal
        id="test-ref-modal"
        opened={isRefModalOpen}
        onClose={handleRefModalClose}
        centered
        title={<IconScan size={36} stroke={1} color="var(--mantine-color-gray-4)" />}
        closeButtonProps={{ 'aria-label': 'Close modal' }}
        returnFocus={false}
      >
        <form onSubmit={referenceForm.onSubmit(handleReferenceFormSubmit)}>
          <TextInput
            data-autofocus
            key={referenceForm.key('referenceNumber')}
            label="Enter or scan reference number"
            // https://github.com/mantinedev/mantine/issues/6611
            // Have to use style prop to override the font size, but if we are having to do this a lot
            // we should consider creating a custom component that wraps TextInput using the Styles API
            labelProps={{ style: { fontSize: 'var(--mantine-font-size-lg)' } }}
            {...referenceForm.getInputProps('referenceNumber')}
          />
          <Group justify="flex-end" mt="md" gap="xs">
            <Button variant="transparent" onClick={handleRefModalClose}>
              Cancel
            </Button>
            <Button
              loading={lookupResponse?.isLoading}
              loaderProps={{ type: 'dots' }}
              type="submit"
            >
              Find
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        id="test-box-loc-modal"
        opened={isBoxModalOpen}
        onClose={handleBoxModalClose}
        centered
        title={<IconPackage size={36} color="var(--mantine-color-gray-4)" />}
        closeButtonProps={{ 'aria-label': 'Close modal' }}
        returnFocus={false}
      >
        <form onSubmit={boxLocationForm.onSubmit(handleBoxLocationFormSubmit)}>
          <TextInput
            data-autofocus
            key={boxLocationForm.key('boxLocation')}
            label="Enter or scan Box Location Number"
            labelProps={{ style: { fontSize: 'var(--mantine-font-size-lg)' } }}
            {...boxLocationForm.getInputProps('boxLocation')}
            onFocus={handleFocus}
            inputContainer={(children) => (
              <Group>
                <Text size="sm">{lookupResponse?.woolLotRecordNumber}</Text>
                {children}
              </Group>
            )}
          />
          <Group justify="flex-end" mt="md" gap="xs">
            <Button variant="transparent" onClick={handleBoxModalClose}>
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  )
}
