import { gql } from 'graphql-request'

export const GetLotDetailsGql = gql`
  query listLotDetails(
    $stationRecordNumber: String!
    $saleAndSeason: String
    $season: String
    $purchaseAccountName: String
    $result: WoolSoldStatus
    $breed: Breed
    $stockAge: StockAge
  ) {
    listLotDetails(
      request: {
        stationRecordNumber: $stationRecordNumber
        saleAndSeason: $saleAndSeason
        purchaseAccountName: $purchaseAccountName
        season: $season
        result: $result
        breed: $breed
        stock: $stockAge
      }
    ) {
      results {
        referenceNumber
        bales
        cleanKgs
        code
        comment
        coreTestCoefficientOfVariationLaserscan
        coreTestColourYZ
        coreTestMicron
        coreTestMicronAirflow
        coreTestMicronLaserscan
        coreTestMicronOfda
        coreTestPointOfBreakBase
        coreTestPointOfBreakMid
        coreTestPointOfBreakTip
        coreTestStapleAverageStrength
        coreTestStapleLengthGreasy
        coreTestStapleStrength
        coreTestVegetableMatterBase
        coreTestYield
        description
        grossProceeds
        lineNumber
        lottedDate
        netKgs
        paymentDate
        priceClean
        priceGreasy
        purchaseAccountName
        qualityScheme2
        recordType
        result
        saleAccount
        season
        saleAndSeason
        sample
        type
        weighDate
      }
    }
  }
`
