import { useParams } from 'react-router-dom'
import { Group, Stack, Title } from '@mantine/core'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { WoolContractsTable } from '@/components/stations/wool-data/wool-contracts-table/wool-contracts-table'
import { GetWoolContractsGql } from '@/graphql/queries/get-wool-contracts.graphql'

export function WoolContracts() {
  // Get the station record number from the URL
  const { stationRecordNumber } = useParams()

  // Get the wool contracts data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['woolContractsData', stationRecordNumber],
    gql: GetWoolContractsGql,
    queryParams: {
      stationRecordNumber,
    },
    enabled: !!stationRecordNumber,
  })

  return (
    <Stack>
      <Group>
        <Title order={2} data-testid="station-wool-data-wool-contracts">
          Wool Contracts
        </Title>
      </Group>
      <WoolContractsTable
        data={data?.listWoolContracts?.results ?? []}
        isLoading={isLoading}
        isError={isError}
        sumOfBalesContracted={data?.listWoolContracts?.sumOfBalesContracted}
      />
    </Stack>
  )
}
