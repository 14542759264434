// These theme values are defined in the 'NZM Hub' Figma design file
import 'inter-ui/inter.css'
import { createTheme, rem } from '@mantine/core'

export const theme = createTheme({
  // Colours
  primaryColor: 'blue',
  primaryShade: 6,
  colors: {
    gray: [
      '#FAFAFA',
      '#F5F5F5',
      '#EFEFEF',
      '#D4D4D4',
      '#A3A3A3',
      '#737373',
      '#525252',
      '#404040',
      '#262626',
      '#171717',
      '#0A0A0A',
    ],
    blue: [
      '#F0F9FF',
      '#E0F2FE',
      '#BAE6FD',
      '#7DD3FC',
      '#38BDF8',
      '#0C9EE0',
      '#007BC0',
      '#0369A1',
      '#075985',
      '#0C4A6E',
      '#082F49',
    ],
    green: [
      '#EFFDE9',
      '#E1F7D7',
      '#C2EBAF',
      '#A2E084',
      '#86D760',
      '#74D149',
      '#6ACE3C',
      '#58B62D',
      '#4CA125',
      '#3E8C19',
      '#215F04',
    ],
    red: [
      '#FFE9E9',
      '#FFD0D0',
      '#FD9D9D',
      '#FC6766',
      '#FC3C39',
      '#FC231C',
      '#FD160E',
      '#E20A03',
      '#C90000',
      '#B00000',
      '#890000',
    ],
    yellow: [
      '#FFF4E2',
      '#FFE9CD',
      '#FED09D',
      '#FBB768',
      '#F9A03D',
      '#F89320',
      '#F98C0F',
      '#DE7802',
      '#C56A00',
      '#AC5A00',
      '#8D4A00',
    ],
  },

  // Typography
  fontFamily: 'Inter',
  fontSizes: {
    xxs: rem(10),
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(18),
    xl: rem(20),
    xxl: rem(22),
    xxxl: rem(24),
  },
  headings: {
    fontFamily: 'Inter',
    fontWeight: '600',
    textWrap: 'balance',
  },

  // Radius
  defaultRadius: 'md',

  // Spacing
  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '3rem',
    xxxl: '4rem',
  },

  // Custom default component props
  components: {
    Tabs: {
      styles: {
        panel: {
          minWidth: 0,
        },
      },
    },
  },
})
