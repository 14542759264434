import { MRT_Row } from 'mantine-react-table'

// Return value reference
// -1  a < b    A should appear before B
//  0  a === b  A and B are equal
//  1  a > b    A should appear after B

/**
 * Sorts two rows based on the numeric values extracted from a specified column.
 *
 * @param {MRT_Row<any>} rowA - The first row to compare.
 * @param {MRT_Row<any>} rowB - The second row to compare.
 * @param {string} columnId - The ID of the column from which to extract the values.
 * @returns {number} - Returns 1 if the value from rowA is greater than the value from rowB,
 *                     -1 if the value from rowA is less than the value from rowB,
 *                     and 0 if they are equal. If one value is present and the other is not,
 *                     the row with the value is considered greater.
 */
export const sortLotNumbers: any = (rowA: MRT_Row<any>, rowB: MRT_Row<any>, columnId: string) => {
  const valueA = getNumberFromString(rowA.getValue<string>(columnId))
  const valueB = getNumberFromString(rowB.getValue<string>(columnId))
  if (valueA && valueB) {
    if (valueA > valueB) {
      return 1
    }
    if (valueA < valueB) {
      return -1
    }
    return 0
  }
  if (valueA && !valueB) {
    return 1
  }
  if (!valueA && valueB) {
    return -1
  }
  return 0
}

/**
 * Extracts the first number found in a given string.
 *
 * @param str - The input string from which to extract the number.
 * @returns The extracted number, or `undefined` if no valid number is found.
 */
export const getNumberFromString = (str: string | undefined): number | undefined => {
  if (!str) {
    return undefined
  }
  const num = parseInt(str.replace(/^\D+/, ''), 10)
  return Number.isNaN(num) ? undefined : num
}
