import { Grid, Group, Stack } from '@mantine/core'
import { useContainerQuery } from 'react-container-query'
import { ValueGroup } from '@/components/shared/value-group'
import { Value } from '@/components/shared/value'
import { PillAlert } from '@/components/shared/pill-alert'
import classes from './typing-program-details.module.css'
import { containerQueries } from '@/components/typing/program/typing-program-details/container-queries'
import { WoolLotDataProps } from '@/components/typing/program/typing-program-types'

type TypingProgramDetailsProps = {
  data: WoolLotDataProps | null
  isLoading: boolean
}

export function TypingProgramDetails({ data, isLoading }: TypingProgramDetailsProps) {
  const [params, containerRef] = useContainerQuery(containerQueries, {})
  const valueFontSize = params.isNarrowContainer ? 'md' : 'xxl'

  return (
    <div ref={containerRef} className={classes.container}>
      {(data || isLoading) && (
        <Stack>
          <Grid gutter={params.isNarrowContainer ? 'sm' : 'md'}>
            {/* First row */}
            <Grid.Col span={{ base: 3, md: 1.5 }}>
              <ValueGroup title="P Org">
                <Value
                  value={data?.stationRecordNumber}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 9, md: 5 }}>
              <ValueGroup title="Purchase Account">
                <Group>
                  <Value
                    value={data?.purchaseAccountName}
                    emphasiseValue
                    size={valueFontSize}
                    isLoading={isLoading}
                  />
                  {!isLoading && data?.purchaseAccountWarning && (
                    <PillAlert text={data?.purchaseAccountWarning} />
                  )}
                </Group>
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.7 }}>
              <ValueGroup title="Sale">
                <Value
                  value={data?.woolLotSaleAndSeason}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.2 }}>
              <ValueGroup title="Bales">
                <Value
                  value={data?.woolLotBales}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.3 }}>
              <ValueGroup title="Lot">
                <Value
                  value={data?.woolLotCode}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.3 }}>
              <ValueGroup title="Line">
                <Value
                  value={data?.woolLotLineNumber}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>

            {/* Second row */}
            <Grid.Col span={{ base: 3, md: 1.2 }}>
              <ValueGroup title="Micron">
                <Value
                  value={data?.coreTestMicronLaserscan}
                  unit="LS"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.coreTestMicronOfda}
                  unit="OF"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                {/* Never show while isLoading as we want to only show two isLoading skeletons for Micron */}
                {!isLoading && (
                  <Value
                    value={data?.coreTestMicronAirflow}
                    unit="AF"
                    emphasiseValue
                    size={valueFontSize}
                    isLoading={false}
                  />
                )}
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 0.8 }}>
              <ValueGroup title="Y-Z">
                <Value
                  value={data?.coreTestColourYZ}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.3 }}>
              <ValueGroup title="Yield">
                <Value
                  value={data?.coreTestVegetableMatterBase}
                  unit="VM"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.coreTestYield1}
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.1 }}>
              <ValueGroup title="B/S">
                <Value
                  value={data?.coreTestBurrPercentage}
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.coreTestSeedShivePercentage}
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.3 }}>
              <ValueGroup title="Mic CV">
                <Value
                  value={data?.coreTestCoefficientOfVariationLaserscan}
                  unit="CV"
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.coreTestCurvatureMeanLaser}
                  unit="CUR"
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.1 }}>
              <ValueGroup title="SS">
                <Value
                  value={data?.coreTestStapleStrength}
                  unit="NKT"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.coreTestStapleAverageStrength}
                  unit="SS25"
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.1 }}>
              <ValueGroup title="SL">
                <Value
                  value={data?.coreTestStapleLengthGreasy}
                  unit="MM"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.coreTestStapleCoefficientOfLengthVariation}
                  unit="CV%"
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.5 }}>
              <ValueGroup title="POB (T M B)">
                <Group gap="xs" wrap="nowrap">
                  <Value
                    value={data?.coreTestPointOfBreakTip}
                    size={valueFontSize}
                    isLoading={isLoading}
                  />
                  <Value
                    value={data?.coreTestPointOfBreakMid}
                    size={valueFontSize}
                    isLoading={isLoading}
                  />
                  <Value
                    value={data?.coreTestPointOfBreakBase}
                    size={valueFontSize}
                    isLoading={isLoading}
                  />
                </Group>
                <Value
                  value={data?.coreTestHauteurLength}
                  unit="TH"
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.3 }}>
              <ValueGroup title="Cert">
                <Value
                  value={data?.qualityScheme}
                  emphasiseValue
                  valueWarning={data?.qualitySchemeWarning}
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <div>{!isLoading && data?.qualitySchemeWarning && <PillAlert text="Cert" />}</div>
              </ValueGroup>
            </Grid.Col>
            <Grid.Col span={{ base: 3, md: 1.3 }}>
              <ValueGroup title="Total">
                <Value
                  value={data?.woolLotsTotalKilosNet}
                  unit="NET"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
                <Value
                  value={data?.woolLotsTotalKilosClean}
                  unit="CLN"
                  emphasiseValue
                  size={valueFontSize}
                  isLoading={isLoading}
                />
              </ValueGroup>
            </Grid.Col>
          </Grid>
        </Stack>
      )}
    </div>
  )
}
